::-webkit-scrollbar-track {
  background-color: #c2c7d0;
}
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background: #4f5962;
}
::-webkit-scrollbar-thumb {
  background: #4f5962;
}
::-webkit-scrollbar-corner{
  width: 1px;
  height: 2px;
}
/* body{
  overflow-x: hidden;
} */